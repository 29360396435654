<template>
  <div>
    <NavBar back="/" :msg="$route.params.name" openLeft="1" />
    <Category />
    <section v-if="$route.params.name == '直播回放'">
      <van-tabs v-model="active">
        <van-tab
          :title="item.vc_name"
          v-for="(item, index) in tablist"
          :key="index"
        >
          <VideoList :hj="item.vc_id" :newisid="item.is_live" />
        </van-tab>
      </van-tabs>
    </section>
    <VideoList v-else :hj="$route.params.id" :newisid="$route.params.isid" />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Category from "@/components/Category.vue";
import VideoList from "@/components/VideoList.vue";
import { liveCatdata } from "@/api/api"; // 直播回放独有
export default {
  name: "ClassifyList",
  components: {
    Category,
    VideoList,
    NavBar,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      timer: "",
      tablist: [],
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "font-size:12px");
  },
  mounted() {
    this.timer = setTimeout(() => {
      liveCatdata()
        .then(({ data: { data } }) => {
          this.tablist = data;
          console.log(data);
        })
        .catch((err) => {
          console.log("失败了" + err);
        });
    }, 500);
  },
};
</script>

<style lang="scss">
.category {
  .router-link-active:nth-child(1) {
    span {
      color: #f9a86a;
    }
  }
  .router-link-active:nth-child(2) {
    span {
      color: #fada52;
    }
  }
  .router-link-active:nth-child(3) {
    span {
      color: #985441;
    }
  }
  .router-link-active:nth-child(4) {
    span {
      color: #cda660;
    }
  }
}
</style>
